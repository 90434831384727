<template>
    <div class="AdminMChoices">
        <div v-show="saving" class="saving">Saving...</div>

        <div class="hint" v-show="hint">{{hint}}</div>

        <div class="dialog" v-if="dialog">
            <div class="dialog-body">
                
                <textarea v-model="new_description" style="width: 100%; height: 200px;" />

                <hr>
                <button @click="save_description">Save</button>
                &nbsp;
                <a href="javascript:void(0)" @click="dialog=false">Cancel</a>
            </div>
        </div>

        <label><input type="checkbox" v-model="show_controls"> Show controls</label>
        
        <div v-for="cat in categories">
            <h3>{{ cat.name }} <a :href="`/admin/maturity/mcategory/${cat.id}/change/`"><img src="/static/admin/img/icon-changelink.svg"></a></h3>
            <table>
                <tr>
                    <td style="width: 270px"> </td>
                    <th v-for="ch in cat.choices" style="width: 180px">
                        {{ ch.value }}
                        <br>
                        <input class="score" v-model="ch.score" @change="save_score(ch)">
                    </th>
                </tr>
                <tr v-for="ctrl in cat.controls" v-if="show_controls">
                    <th>
                        {{ ctrl.name }}
                        <a :href="`/admin/maturity/mcontrol/${ctrl.id}/change/`" style="opacity: 0.3;"><img src="/static/admin/img/icon-changelink.svg"></a>
                    </th>
                    <td v-for="ch in cat.choices"
                            class="defcell"
                            @mouseover="hint = definitions[ctrl.id][ch.id]"
                            @mouseleave="hint = ''">
                            <a href="javascript:void(0)" @click="show_dialog(ctrl.id, ch.id)" :class="{'empty': definitions[ctrl.id][ch.id] == '(empty)'}">
                            {{ definitions[ctrl.id][ch.id] | cut }}
                            </a>
                    </td>
                </tr>
                <tr v-if="show_controls">
                    <td colspan="2"><a :href="`/admin/maturity/mcontrol/add/?category=${cat.id}&sort_order=${cat.controls.length + 1}`">+ add control</a></td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>

import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"



export default {
    name: 'AdminMChoices',
    data() {
        return {
            'show_controls': true,
            'categories': ADMIN_CHOICES.categories,
            'definitions_list': ADMIN_CHOICES.definitions,
            'hint': '',
            'saving': false,
            'dialog': false,
            'dialog_ctrl_id': null,
            'dialog_choice_id': null,
            'new_description': '',
        }
    },
    computed: {
        definitions() {
            let result = {}
            this.categories.forEach(cat => {
                cat.controls.forEach(ctrl => {
                    result[ctrl.id] = {}
                    cat.choices.forEach(ch => {
                        result[ctrl.id][ch.id] = "(empty)"
                    })
                })
            })
            this.definitions_list.forEach(d => {
                result[d.control_id][d.choice_id] = d.description || '(empty)'
            })
            return result
        }
    },
    methods: {
        show_dialog(ctrl_id, ch_id) {
            this.dialog = true
            this.dialog_ctrl_id = ctrl_id
            this.dialog_choice_id = ch_id
            this.new_description = this.definitions[ctrl_id][ch_id]
        },
        async save_score(choice) {
            this.saving = true
            try {
                const response = await axios.post('/admin/api/maturity/score/', {'id': choice.id, 'score': choice.score})
            } catch (error) {
                alert('Sorry, we had an error saving this object: ' + error)
            }
            this.saving = false
        },
        async save_description() {
            this.saving = true
            this.dialog = false
            try {
                const response = await axios.post('/admin/api/maturity/definition/', {
                    'ctrl_id': this.dialog_ctrl_id, 
                    'choice_id': this.dialog_choice_id, 
                    'description': this.new_description,
                    'language': window.CHOICES_LANG,
                })
                //applying change
                let is_new = true
                this.definitions_list.forEach(d => {
                    if (d.control_id == this.dialog_ctrl_id && d.choice_id == this.dialog_choice_id) {
                        d.description = this.new_description
                        is_new = false
                    }
                })

                if (is_new) {
                    this.definitions_list.push({
                        control_id: this.dialog_ctrl_id, 
                        choice_id: this.dialog_choice_id, 
                        description: this.new_description,
                    })
                }

            } catch (error) {
                alert('Sorry, we had an error saving this object: ' + error)
            }
            this.saving = false
        } 
    },
    filters: {
        cut(value) {
            if (value.length > 10) {
                return value.slice(0, 10) + '...'
            }
            return value
        }
    }

}
</script>

<style scoped>
h3 {
    background-color: #eee;
    margin-top: 20px;
    padding: 7px
}
.saving {
   background-color: antiquewhite; 
   position: fixed;
   top: 0;
   left: 0;
   width: 100px;
}
.hint {
    background-color: antiquewhite;
    width: 400px;
    top: 10px;
    right: 10px;
    position: fixed;
    padding: 3px;
    white-space: pre-wrap;
}
.defcell:hover {
    background-color: #eee;
}

.dialog {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0, 0.4);
}
.dialog .dialog-body {

    background-color: white;
    width: 400px;
    margin: auto;
    margin-top: 200px;
    padding: 20px;

}
.score {
    background-color:azure;
    border: 1px solid #ccc;
    width: 42px;
    padding: 1px 4px;
}
.empty {
    color: rgba(0, 0, 0, 0.3);
}
</style>
